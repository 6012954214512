import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Om from "./routes/Om";
import JegTilbyder from "./routes/JegTilbyder";
import AndreSiger from "./routes/AndreSiger";
import Kontakt from "./routes/Kontakt";
import ForVirksomheder from "./routes/ForVirksomheder";


ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="om" element={<Om />} />
      <Route path="jegtilbyder" element={<JegTilbyder />} />
      <Route path="forvirksomheder" element={<ForVirksomheder />} />
      <Route path="andresiger" element={<AndreSiger />} />
     
      <Route path="kontakt" element={<Kontakt />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
