import './BottomMobile.css';
import React, { Component } from 'react';

class BottomMobile extends Component {

    render = () => {
        return (

            <div id="1" class="bottomMobile">
                <div id="8" class="bottomRightMobile">
                    <div id="6" class="bottomRightTextMobile">
                        Følg mig på
                    </div>
                    <div class="bottomButtonsMobile">

                        <a href="https://www.instagram.com/maj_brix_coaching/" target="_blank" rel="noopener noreferrer"><input type="image" src="/instagram.jpeg" class="bottomRightButtonMobile" /> </a>
                        <a href="https://www.facebook.com/Majbrixcoaching" target="_blank" rel="noopener noreferrer"><input type="image" src="/facebook.png" class="bottomRightButtonMobile" /> </a>
                        <a href="https://www.linkedin.com/in/maj-brix-pedersen-0031293a/" target="_blank" rel="noopener noreferrer"><input type="image" src="/linkedin.jpeg" class="bottomRightButtonMobile" /> </a>


                    </div>
                </div>

                <div id="6" class="bottomLeftMobile">
                    <div id="6" class="bottomLeftTextMobile">
                        Maj Brix Coaching
                        <br></br>
                        Syrenvej 19
                        <br></br>
                        9310 Vodskov</div>
                </div>
                <div id="7" class="bottomMiddleMobile">
                    <div id="6" class="bottomMiddleTextMobile">
                        CVR: 43 05 55 18
                    </div>
                </div>
            </div>


        );
    }
}

export default BottomMobile;



