
import './Om.css';
import './OmMobile.css';
import './MobileCommon.css';
import React, { Component } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Container } from "reactstrap";
import MajMenu from './MajMenu';
import Bottom from './Bottom';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import MajMenuMobile from './MajMenuMobile';
import BottomMobile from './BottomMobile';
import Text from 'react-text';


class Om extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isMobile: window.innerWidth <= 500
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    updateIsMobile() {
        this.setState({
            isMobile: window.innerWidth <= 500
        });
    }


    componentDidMount() {
        window.addEventListener('resize', this.updateIsMobile);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateIsMobile);
    }

    render = () => {

        const name = "MAJ BRIX PEDERSEN"
        const box1headline = "Hvorfor er jeg blevet coach?"
        const box1Text = <Text language="dk">
            Jeg har altid været nysgerrig på mennesker, relationer og på hvordan vi hver især har vores indre styrker men også indre begrænsninger.
            <br></br> <br></br>
            Personligt har jeg haft tendens til at:
            <ul>
                <li>Knokle</li>
                <li>Overtænke</li>
                <li>Tage overansvar </li>
                <li>Sætte andre menneskers behov før mine egne </li>
            </ul>

            Som nybagt mor til nr. 2 og godt på vej op af karrierestigen gik jeg i 2020 ned med stress og angst. <br></br> <br></br>

            Det blev vigtigt for mig, at lære mig selv at kende i dybden, så jeg aldrig igen skulle komme i så stor ubalance. <br></br> <br></br>

            Coaching blev et kæmpe wake up call for mig. Jeg fandt svarene på, hvorfor jeg helt ubevidst havde presset mig selv så hårdt, at min krop måtte sige fra, og samtidig fandt jeg vejen til at skabe mere balance i mit liv. <br></br> <br></br>

            I coachingen fandt jeg også det krydsfelt, hvor mine genizoner, værdier og min passion for at hjælpe andre mennesker går op i en højere enhed. Jeg brænder for at hjælpe mennesker med at opdage, at der altid er en vej ud af stress, og at der ligger et liv på den anden side, som er langt mere i tråd med, hvem du i virkeligheden er. <br></br> <br></br>
        </Text>

        const box2Headline = "Faglig baggrund"
        const box2Text = <Text language="dk">
            NLP Coach Practitioner v/ NLP Akademiet, 2021<br></br><br></br>

            NLP Master Coach Practitioner v/ NLP Akademiet, 2022<br></br><br></br>

            NLP Stress Coach Master Practitioner v/NLP Aalborg, Morten Gelbek, 2023<br></br><br></br>

            NLP Akademiet følger NLP Foreningen Danmarks standarder og den internationale certificering ICTA.<br></br><br></br>

            Derudover har jeg 10 års efaring med kommunikation, kulturformidling, projekt- og forandringsledelse fra IT branchen og arbejder lige nu med HR og rekruttering ved siden af min coaching. </Text>


        const box3Headline = "Andre siger"
        const box3Text = <Text language="dk">
            <i>“Jeg vil anbefale alle at booke en session hos Maj. Hun rummer et skarpt overblik og holder rummet for, at man selv kærligt finder frem til en løsning.”</i>
        </Text >

        const andreSigerButton = "Læs mere om hvad andre siger"

        const box4Headline = "Hvorfor coaching?"
        const box4Text = <Text language="dk">
            Vi mennesker udvikler os hele tiden. Det er bare ikke altid at vores værdier, overbevisninger og tanke- og adfærdsmønstre følger med, og nogle gange kan de komme til at spænde ben for det, vi længes efter.<br></br><br></br>

            Ligesom vi vedligeholder vores kroppe med f.eks. kost, motion og massage, så har vores sind også brug for et eftersyn engang i mellem, for at rydde op i det, som er forældet og gøre plads til det, som banker på.<br></br><br></br>

            Coaching er et fantastisk redskab til personlig udvikling. Du får bevidstgjort det ubevidste og får værktøjer med dig, som du vil kunne bruge resten af dit liv til at sikre, at du har dig selv med i alle dine roller på jobbet, i hjemmet og som dit autentiske jeg.
        </Text >

        const box5Headline = "Hvad er NLP?"
        const box5Text = <Text language="dk">
            NLP står for Neuro Lingvistisk Programmering. <br></br><br></br>
            Neuro - handler om sammenhængen imellem vores nervebaner og vores bevidsthed.<br></br><br></br>
            Lingvistisk - handler om, hvordan vi med ord kan påvirke egne og andres tanker.<br></br><br></br>
            Programmering - handler om hvordan vi gennem livet er blevet programmeret med bestemte ubevidste overlevelsesstrategier. Ved at blive bevidste om disse strategier, og organisere vores neurologiske og sproglige systemer, kan vi også skabe nye successfulde strategier til at opnå det, vi ønsker.
        </Text >
        if (!this.state.isMobile) {
            return (
                <div>

                    <MajMenu />

                    <div class="aboutheading">{name}</div>

                    <div id="1" class="aboutwelcomeOm">
                        <div id="2" class="left">
                            <div class="box">
                                <div class="margin10Top">
                                    <div id="v1" class="boxText">
                                        <div class="boxHeadline">
                                            {box1headline}
                                        </div>
                                        <br></br>
                                        {box1Text}
                                    </div>
                                </div>
                                <div class="margin10Top">

                                    <div id="v1" class="boxText">
                                        <div class="boxHeadline">
                                            {box2Headline}
                                        </div>
                                        <br></br>
                                        {box2Text}

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="3" class="right">


                            <div id="1" class="omPictureRight">
                            </div>

                            <div class="margin10Top">
                                <div id="v1" class="boxText">
                                    <div class="boxHeadline">
                                        {box3Headline}
                                    </div>
                                    <br></br>
                                    {box3Text}

                                    <br></br>
                                    <br></br>
                                    <div >
                                        <Link to="/andresiger">
                                            <button type="button" class="aboutwelcomeRightBodyButtonOm">{andreSigerButton}</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="5" class="aboutinfo">
                        <div id="6" class="left">
                            <div class="margin10Top">
                                <div id="6" class="box">
                                    <div class="boxText">
                                        <div id="6" class="white">
                                            <div id="6" class="boxHeadline">
                                                {box4Headline}
                                            </div>
                                            <br></br>
                                            {box4Text}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="right" >
                            <div class="margin10Top">
                                <div id="6" class="box">
                                    <div class="boxText">
                                        <div id="6" class="white">
                                            <div id="6" class="boxHeadline">{box5Headline}</div>
                                            <br></br>
                                            {box5Text}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Bottom />
                </div >
            );
        } else {
            return (
                <div>

                    <MajMenuMobile />

                    <div class="headingMobile">{name}</div>

                    <div class="omsections">

                        <div class="omsectionsHeading">{box1headline}</div>


                        {box1Text}
                    </div>

                    <div id="1" class="omPictureMobile">
                    </div>
                    
                    <div class="omsections">

                        <div class="omsectionsHeading">{box2Headline}</div>

                        {box2Text}

                    </div>

                    

                    <div class="omsections">

                        <div class="omsectionsHeading">{box3Headline}</div>

                        {box3Text}

                        <div >
                            <Link to="/andresiger">
                                <button type="button" class="readMoreButton">{andreSigerButton}</button>
                            </Link>
                        </div>


                    </div>

                    <div id="6" class="squareSections">
                        <div id="6" class="squareSectionsText">
                            <div class="smallBoxTextMobile">{box4Headline}</div>
                            {box4Text}

                        </div>
                    </div>

                    <div id="6" class="squareSections">
                        <div id="6" class="squareSectionsText">

                            <div class="smallBoxTextMobile">{box5Headline}</div>
                            {box5Text}


                        </div>
                    </div>

                    <BottomMobile />

                </div>
            )


                ;
        }
    }


}

export default Om;



