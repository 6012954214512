import './MajMenu.css';
import React, { Component } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Container } from "reactstrap";
import Om from './MajMenu.js'
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";

class MajMenu extends Component {


  render = () => {


    return (

      <div class="topnav">
        <div>
          <a href="/"><input type="image" src="/logo.jpeg" class="topnav-left" /> </a>
        </div>
        <div class="topnav-right">
          <a href="om" font="Lato">OM</a>
          <a href="jegtilbyder">COACHINGFORLØB</a>
          <a href="forvirksomheder">FOR VIRKSOMHEDER</a>
          <a href="andresiger">ANDRE SIGER</a>
          <a href="kontakt">KONTAKT</a>
        </div>
      </div>

    );
  }

}

export default MajMenu;



