import './Bottom.css';
import React, { Component } from 'react';

class Bottom extends Component {


    render = () => {
        return (
           
                <div id="1" class="bottom">
                    <div id="8" class="bottomRight">
                        <div id="6" class="bottomRightText">
                            Følg mig på
                        </div>
                        <div class="bottomButtons">

                            <a href="https://www.instagram.com/maj_brix_coaching/" target="_blank" rel="noopener noreferrer"><input type="image" src="/instagram.jpeg" class="bottomRightButton" /> </a>
                            <a href="https://www.facebook.com/Majbrixcoaching" target="_blank" rel="noopener noreferrer"><input type="image" src="/facebook.png" class="bottomRightButton" /> </a>
                            <a href="https://www.linkedin.com/in/maj-brix-pedersen-0031293a/" target="_blank" rel="noopener noreferrer"><input type="image" src="/linkedin.jpeg" class="bottomRightButton" /> </a>


                        </div>
                    </div>

                    <div id="6" class="bottomLeft">
                        <div id="6" class="bottomLeftText">
                            Maj Brix Coaching
                            <br></br>
                            Syrenvej 19
                            <br></br>
                            9310 Vodskov</div>
                    </div>
                    <div id="7" class="bottomMiddle">
                        <div id="6" class="bottomMiddleText">
                            CVR: 43 05 55 18
                        </div>
                    </div>
                </div>
           

        );
    }
}

export default Bottom;



