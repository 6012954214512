import './ContactForm.css';
import React, { useState } from "react";

const ContactForm = () => {
    const [status, setStatus] = useState("Send");
    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus("Sending...");
        const { name, email, subject, message, samtykke } = e.target.elements;
        let details = {
            name: name.value,
            email: email.value,
            subject: subject.value,
            message: message.value,
            samtykke: samtykke.value

        };
        let response = await fetch("https://majbrixcoaching.dk:5000/contact", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(details),
        });
        setStatus("Send");
        let result = await response.json();
        alert(result.status);
    };
    return (
        <form onSubmit={handleSubmit}>
            <br></br>

            <div>
                <label htmlFor="name">Navn:</label>
                <input type="text" id="name" required />
            </div>
            <div>
                <label htmlFor="email">E-mail:</label>
                <input type="text" id="email" required />
            </div>
            <div>
                <label htmlFor="subject">Emne:</label>
                <input type="text" id="subject" required />
            </div>
            <div>
                <label htmlFor="message">Besked:</label>
                <textarea id="message" required rows="5" />
            </div>
            <div>

                <input type="checkbox" id="samtykke" />
                <label htmlFor="samtykke"> Jeg giver hermed samtykke til, at virksomheden må opbevare og behandle de oplysninger, jeg har afgivet i formularen i forbindelse med min henvendelse. Samtykket er afgivet frit og kan til enhver tid trækkes tilbage. </label>

            </div>
            <br></br>

            <button type="submit" class="submitButton">{status}</button>
        </form>
    );
};

export default ContactForm;