
import './Kontakt.css';
import './KontaktMobile.css'
import './MobileCommon.css'
import React, { Component } from 'react';

import MajMenu from './MajMenu';
import Bottom from './Bottom';
import ContactForm from './ContactForm';
import MajMenuMobile from './MajMenuMobile';
import BottomMobile from './BottomMobile';
import ContactFormMobile from './ContactFormMobile';

class Kontakt extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isMobile: window.innerWidth <= 500
        };
    }

    updateIsMobile() {
        this.setState({
            isMobile: window.innerWidth <= 500
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        window.addEventListener('resize', this.updateIsMobile);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateIsMobile);
    }

    render = () => {

        if (!this.state.isMobile) {
            return (
                <div>
                    <MajMenu />

                    <div class="aboutheading">KONTAKT</div>
                    <div>
                        <div id="1" class="aboutwelcome">
                            <div id="2" class="kontaktwelcomeLeft">
                                <div class="h2">Kontakt</div>
                                <br></br>
                                Har du spørgsmål eller ønsker du at booke en gratis og uforpligtende afklaringssamtale, så kontakt mig venligst via mail på <a href="mailto:maj@majbrixcoaching.dk">maj@majbrixcoaching.dk</a>  <br></br>    <br></br>

                                Du er også velkommen til at sende mig en besked via <a href="https://www.linkedin.com/in/maj-brix-pedersen-0031293a">LinkedIn</a> eller <a href="https://www.instagram.com/maj_brix_coaching">Instagram</a>.    <br></br>    <br></br>

                                Jeg glæder mig til at høre fra dig.    <br></br>    <br></br>

                                <br></br>


                                <div class="h2">Kontaktinformation</div>
                                <br></br>

                                Maj Brix Coaching    <br></br>
                                Syrenvej 19    <br></br>
                                9310 Vodskov    <br></br>

                                Tlf. 25346787    <br></br>

                                E-mail: <a href="mailto:maj@majbrixcoaching.dk">maj@majbrixcoaching.dk</a>    <br></br>    <br></br>
                            </div>
                        
                        </div>

                    </div>
                    <Bottom />

                </div>


            );
        } else {
            return (
                <div>
                    <MajMenuMobile />
                    <div class="headingMobile">KONTAKT</div>

                    <div class="textSections">
                        Har du spørgsmål eller ønsker du at booke en gratis og uforpligtende afklaringssamtale, så kontakt mig venligst via mail på <a href="mailto:maj@majbrixcoaching.dk">maj@majbrixcoaching.dk</a>    <br></br>    <br></br>

                        Du er også velkommen til at sende mig en besked via <a href="https://www.linkedin.com/in/maj-brix-pedersen-0031293a">LinkedIn</a> eller <a href="https://www.instagram.com/maj_brix_coaching">Instagram</a>.    <br></br>    <br></br>

                        Jeg glæder mig til at høre fra dig. <br></br>    <br></br>


                    </div>



                    <div class="textSectionsKontakt">
                        <div class="textSectionsHeadingKontakt">Kontaktinformation</div>

                        Maj Brix Coaching    <br></br>
                        Syrenvej 19    <br></br>
                        9310 Vodskov    <br></br>

                        Tlf. 25346787    <br></br>

                        E-mail: <a href="mailto:maj@majbrixcoaching.dk">maj@majbrixcoaching.dk</a>
                    </div>


                
                    <BottomMobile />

                </div>
            )
        }
    }

}

export default Kontakt;



