
import './Common.css';
import './ForVirksomheder.css';
import React, { Component } from 'react';

import MajMenu from './MajMenu';
import Bottom from './Bottom';

import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import MajMenuMobile from './MajMenuMobile';
import './MobileCommon.css';
import BottomMobile from './BottomMobile';


class ForVirksomheder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isMobile: window.innerWidth <= 500
        };
    }

    updateIsMobile() {
        this.setState({
            isMobile: window.innerWidth <= 500

        });
    }


    componentDidMount() {
        window.scrollTo(0, 0);
        window.addEventListener('resize', this.updateIsMobile);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateIsMobile);
    }

    render = () => {

        const headline = "STRESSFOREBYGGELSE I VIRKSOMHEDER"

        const box1Headline = "Stressforebyggelse på agendaen"
        const box1Text1 = "Stress kommer mange steder fra. Fra samfundet, fra jobbet, fra privatlivet, men i lige så høj grad fra det enkelte menneskes egne ubevidste strategier, som han/hun bruger til at håndtere presset fra sine omgivelser."
        const box1Text2 = "Langvarig stress opbygges og forværres over tid. Det er derfor afgørende at sætte ind med hjælp inden stressen udvikler sig til en alvorlig og hæmmende tilstand. Jeg tror på, at samfundet og virksomhederne kan gøre meget for at forebygge stress. Men det er også min erfaring, at stress hos den enkelte bør behandles individuelt, da der er lige så mange årsager til stress, som der er mennesker på jorden."
        const box1Text3 = "Skal vi tage en uforpligtende snak?"

        const kontaktMig = "Kontakt mig"

        const box2Headline = "Virksomhedsoplæg"
        const box2Text1 = "Jeg afholder 1 times oplæg for virksomheder eller større grupper."
        const box2Text2 = "På dette oplæg vil du få en forståelse for hvad stress er, øge din bevidsthed omkring hvordan stress opbygges og lære helt konkrete teknikker til at forebygge stress i hverdagen."
        const box2Text3 = "Jeg bygger oplægget på den nyeste forskning indenfor stress, min erfaring som coach og min egen historie som tidligere stresssygemeldt."

        const box3Headline = "Stressforebyggende coachingforløb"
        const box3Text1 = "Hjælp dine medarbejdere med at tage stress i opløbet."
        const box3Text2 = "1:1 coachingforløb online, fysisk i virksomheden eller i mit lokale i Vodskov"
        const box3Text3 = "8 sessioner af 1 times varighed fordelt over ca. 8 uger"
        const box3Text4 = "Jeg tilpasser coachingforløbet til den enkeltes situation og tilstand. Jeg guider medarbejderen gennem en bevidsthedsudvidelse ift. egne stressmønstre, giver en forståelse for stress og stressreducering, og guider vedkommende til at skabe et liv i balance."
        const box3Text5 = "Kombiner evt. tilbuddet til medarbejderne med virksomhedsoplægget: Forstå og forebyg stress."

        const box4Headline = "Andre siger om oplægget"
        const box4Text1 = "“Majs oplæg var super inspirerende og gav et godt indblik i stress og tankernes verden. Jeg ved nu hvad jeg skal holde øje med, så jeg kan bede om hjælp inden jeg når så langt, at jeg får stress. Derudover fik vi konkrete værktøjer, som kan anvendes både i stressede situationer, men også ift. fx. tankemylder. Maj var virkelig god til at videreformidle sine budskaber og gjorde emnet spændende. Alt i alt et super godt oplæg!”"
        const box4Text2 = "- Sara, deltager på oplægget Forstå og forebyg stress afholdt hos twoday IT Minds"

        if (!this.state.isMobile) {
            return (
                <div>
                    <MajMenu />
                    <div class="aboutheading">{headline}</div>

                    <div id="1" class="forVirksomheder">
                        <div id="2" class="left">

                            <div class="box">
                                <div class="margin10Top">

                                    <div id="v1" class="boxText">
                                        <div class="boxHeadline">
                                            {box1Headline}
                                        </div>

                                        <div class="black">
                                            <br></br>
                                            {box1Text1}<br></br><br></br>

                                            {box1Text2}<br></br><br></br>

                                            {box1Text3}<br></br><br></br>

                                            <br></br><br></br>
                                            <div >
                                                <Link to="/kontakt">   <button type="button" class="kontaktMig" >{kontaktMig}</button></Link>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="pictureLeftBox">
                                <div class="vPictureLeft">

                                </div>

                            </div>

                            <div class="box">

                                <div class="margin10Top">
                                    <div id="6" class="boxText">
                                        <div class="boxHeadline">
                                            {box2Headline}
                                        </div>

                                        <div class="black">
                                            <br></br>
                                            {box2Text1}<br></br><br></br>
                                            {box2Text2}<br></br><br></br>
                                            {box2Text3}<br></br>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div id="3" class="right">
                            <div id="welcomeRightBody" class="andresigeraboutwelcomeRightBody">
                                <div class="pictureRightBox">
                                    <div class="vPictureRight">

                                    </div>
                                </div>
                                <div class="box">

                                    <div class="margin10Top">
                                        <div id="6" class="boxText">
                                            <div class="boxHeadline">
                                                {box3Headline}
                                            </div>
                                            <div class="black">
                                                <br></br>
                                                {box3Text1}<br></br><br></br>

                                                -   {box3Text2}<br></br>
                                                -   {box3Text3}<br></br><br></br>

                                                {box3Text4}<br></br><br></br>

                                                {box3Text5}<br></br><br></br>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="box">

                                    <div class="margin10Top">
                                        <div id="6" class="boxText">
                                            <div class="boxHeadline">
                                                {box4Headline}
                                            </div>
                                            <div class="black">
                                                <br></br>
                                                <i>{box4Text1}</i> <br></br><br></br>

                                                {box4Text2}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                            <br></br>
                        </div>
                    </div>
                    <Bottom />

                </div >


            );
        } else {
            return (
                <div>
                    <MajMenuMobile />
                    <div class="headingMobile">{headline}</div>


                    <div class="omsections">

                        <div class="omsectionsHeading">{box1Headline}</div>


                        {box1Text1}<br></br><br></br>

                        {box1Text2}<br></br><br></br>

                        {box1Text3}<br></br><br></br>



                        <div >
                            <Link to="/kontakt">
                                <button type="button" class="readMoreButton">{kontaktMig}</button>
                            </Link>
                        </div>

                    </div>

                    <div id="1" class="vPictureRightMobile">
                    </div>

                    <div class="omsections">

                        <div class="omsectionsHeading">{box3Headline}</div>


                        {box3Text1}<br></br><br></br>

                        -   {box3Text2}<br></br>
                        -   {box3Text3}<br></br><br></br>

                        {box3Text4}<br></br><br></br>

                        {box3Text5}<br></br><br></br>


                    </div>



                    <div id="1" class="vPictureLeftMobile">
                    </div>

                    <div class="omsections">

                        <div class="omsectionsHeading">{box2Headline}</div>


                        {box2Text1}<br></br><br></br>
                        {box2Text2}<br></br><br></br>
                        {box2Text3}<br></br><br></br>


                    </div>

                    <div class="omsections">

                        <div class="omsectionsHeading">{box4Headline}</div>


                        <i>{box4Text1}</i> <br></br><br></br>

                        {box4Text2}


                    </div>


                    <BottomMobile />

                </div >

            );
        }

    }


}

export default ForVirksomheder;
