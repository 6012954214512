
import './App.css';
import './AppMobile.css';
import React from 'react';
import MajMenu from './MajMenu.js'
import MajMenuMobile from './MajMenuMobile.js'
import Bottom from './Bottom'
import BottomMobile from './BottomMobile'
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';



function App() {
  const isMobile = window.innerWidth <= 500;

  const bigPictureName = "MAJ BRIX COACHING";
  const bigPictureText = "ET LIV I BALANCE STARTER INDEFRA";
  const headline = "Fra ubalance eller stress til balance, overskud og glæde";
  const headlineText1 = "Jeg hedder Maj Brix Pedersen, og jeg er uddannet NLP Master- og Stress Coach.";
  const headlineText2 = "Jeg hjælper dig fra ubalance eller stress til et liv med mere balance, overskud og glæde.";
  const headlineText3 = "Du kan f.eks. opnå at:";
  const headlineText4 = "Blive bedre til at håndtere livets pres og forebygge stress";
  const headlineText5 = "Sætte dig selv fri af dine uhensigtsmæssige stressmønstre";
  const headlineText6 = "Stå stærkt i dig selv gennem højt selvværd og personlig ledelse";
  const headlineText7 = "Få mere balance, overskud og livsglæde";
  const headlineText8 = "Coachingen foregår hos mig i Vodskov i Nordjylland eller online.";
  const bookText = "Book en afklaringssamtale";

  const infoLeftText = "“Det giver en enorm følelse af frihed og styrke, når vi slår autopiloten fra og selv begynder at definere, hvordan vi vil leve vores liv”";
  const infoLeftTextName = "- Maj Brix Pedersen";

  const infoMiddleText = "Med et coachingforløb får du arbejdet i dybden og skabt en udvikling, som holder på den lange bane.";
  const infoMiddleTextHeadline = "Coachingforløb";

  const infoRightText = "Sæt fokus på stressforebyggelse i din virksomhed.";
  const infoRightTextHeadline = "For virksomheder";

  if (!isMobile) {
    return (
      <div>
        <MajMenu />
        <div class="wrapper">
          <div id="1" class="bigPicture">
          </div>
          <div class="bigPictureText">  <div class="majbrixcoaching">{bigPictureName}</div>   <div class="majbrixlivibalance">{bigPictureText}</div></div>
        </div>

        <div id="1" class="margin5Top">

          <div id="2" class="left">
            <div class="boxText">
              <div id="1" class="forsidePicture">
              </div>
            </div>
          </div>
          <div id="3" class="right">

            <div id="1" class="box">
              <div class="black">
                <div class="boxText">
                  <div class="boxHeadline"> {headline} </div>

                  <br></br>
                  {headlineText1} <br></br><br></br>

                  {headlineText2}
                  <br></br><br></br>

                  {headlineText3}

                  <ul>
                    <li> {headlineText4}</li>

                    <li> {headlineText5}</li>

                    <li> {headlineText6}</li>

                    <li>{headlineText7}</li>
                  </ul>

                  {headlineText8} <br></br>
                  <div >
                    <Link to="/kontakt">
                      <button type="button" id="welcomeRightBodyButton" class="welcomeRightBodyButton">{bookText}</button> </Link>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>

        <div id="5" class="info">
          <div id="6" class="infoLeftText">
            <i> {infoLeftText}</i>

            <div class="infoLeftTextName">
              {infoLeftTextName}
            </div>

          </div>

          <div class="infoMiddleText">

            <div class="h4text">{infoMiddleTextHeadline}</div>
            <br></br>
            {infoMiddleText}

            <div class="infoMiddleBodyButtonWrapper">
              <Link to="/jegtilbyder">
                <button type="button" id="welcomeRightBodyButton" class="infoMiddleBodyButton">Læs mere</button> </Link>
            </div>
          </div>


          <div id="6" class="infoRightText">

            <div class="h4text">{infoRightTextHeadline}</div>
            <br></br>
            {infoRightText}
            <div class="infoRightBodyButtonWrapper">
              <Link to="/forvirksomheder">
                <button type="button" id="welcomeRightBodyButton" class="infoRightBodyButton">Læs mere</button>
              </Link>
            </div>
          </div>

        </div>
        <Bottom />
      </div>
    );
  } else {
    return (
      <div >
        <MajMenuMobile />
        <div class="wrapper">
          <div id="1" class="bigPicture">
          </div>
          <div class="bigPictureTextMobile">  <div class="majbrixcoachingMobile">MAJ BRIX COACHING</div>   <div class="majbrixlivibalanceMobile">ET LIV I BALANCE STARTER INDEFRA</div></div>
        </div>

        <div id="3" class="welcomeMobile">
          <div class="welcomeTextMobile">{headline}</div>
          <div id="welcomeBodyMobile" class="welcomeBodyMobile">

            <body> <br></br>{headlineText1} <br></br><br></br>

              {headlineText2}<br></br><br></br>

              {headlineText3}

              <ul>
                <li>{headlineText4}</li>

                <li>{headlineText5}</li>

                <li>{headlineText6}</li>

                <li>{headlineText7}</li>
              </ul>

              {headlineText8}<br></br>

            </body>

            <br></br><br></br>
          </div>
        </div>

        <div >
          <Link to="/kontakt">
            <button type="button" class="welcomeBodyButtonMobile">{bookText}</button> </Link>
        </div>

        <div id="1" class="forsidePictureMobil">
        </div>

        <div id="6" class="infoLeftMobile">
          <div id="6" class="infoLeftTextMobile">
            {infoLeftText}
          </div>
          <div class="infoLeftTextNameMobile">
            {infoLeftTextName}
          </div>
        </div>

        <div class="infoMiddleMobile" position="relative">
          <div class="infoMiddleTextMobile">

            <div class="smallBoxTextMobile">  {infoMiddleTextHeadline}
            </div>
            {infoMiddleText}
            <div class="infoMiddleBodyButtonWrapperMobile">
              <Link to="/jegtilbyder">
                <button type="button" class="infoMiddleBodyButtonMobile">Læs mere</button> </Link>
            </div>
          </div>
        </div>


        <div id="8" class="infoMiddleMobile">
          <div id="6" class="infoMiddleTextMobile">

            <div class="smallBoxTextMobile"> {infoRightTextHeadline} </div>

            {infoRightText}
            <div class="infoMiddleBodyButtonWrapperMobile">
              <Link to="/forvirksomheder">
                <button type="button" class="infoMiddleBodyButtonMobile">Læs mere</button>
              </Link>
            </div>
          </div>

        </div>

        <BottomMobile />
      </div>
    );

  }
}


function Logo() {
  return <h2>Logo</h2>;
}

function Menu() {
  return <h2>menu</h2>;
}

export default App;
