
import './JegTilbyder.css';
import './JegTilbyderMobile.css';
import './Common.css';
import React, { Component } from 'react';

import MajMenu from './MajMenu';
import Bottom from './Bottom';
import Kontakt from './Kontakt';

import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import MajMenuMobile from './MajMenuMobile';
import './MobileCommon.css';
import BottomMobile from './BottomMobile';


class JegTibyder extends Component {


    constructor(props) {
        super(props);
        this.state = {
            isMobile: window.innerWidth <= 500
        };
    }

    updateIsMobile() {
        this.setState({
            isMobile: window.innerWidth <= 500

        });
    }


    componentDidMount() {
        window.scrollTo(0, 0);
        window.addEventListener('resize', this.updateIsMobile);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateIsMobile);
    }

    render = () => {

        const coaching = "COACHING"
        const bookHeadline = "Book en afklaringssamtale"
        const bookText1 = "Jeg vil til enhver tid anbefale, at du booker en gratis og uforpligtende afklaringssamtale med mig inden du investerer i et forløb."
        const bookText2 = "Det er vigtigt, at vi får en fornemmelse af hinanden, så vi kan spore os ind på, om jeg er den rigtige coach for dig."
        const bookText3 = "Samtalen vil tage 20-30 minutter og foregå online. Der kræves ingen forberedelse fra din side."
        const bookButton = "Book afklaringssamtale"

        const middleHeadline = "Coachingforløb"
        const middleText1 = "Personlig udvikling er en proces, som kræver vedholdenhed, støtte og motivation. For at skabe en holdbar forandring er det min erfaring, at vi mødes regelmæssigt over en periode."
        const middleText2 = "Mine coachingforløb skræddersyes til dig, og det du har brug for."
        const middleText3 = "Hver session foregår face-to-face hos mig i Vodskov eller online. En session varer 60 minutter."
        const middleText4 = "Jeg giver 15 % studierabat."

        const laesHvadAndreSiger = "Læs hvad andre siger"

        const bottomHeadline = "Bevidst balance"
        const bottomText1 = "På dette 8 sessioners coachingforløb guider jeg dig til at lede dig selv til en hverdag med mental balance, overskud og glæde."
        const bottomText2 = "Personlig ledelse handler om at lære dig selv at kende i dybden og ændre på det, som ikke længere gavner dig."
        const bottomText3 = "Hvis du føler dig i ubalance, stresset, usikker, eller låst fast i en drænende tilstand, så er det ofte dine ubevidste overlevelsesstrategier, som låser dig fast. Du gør det, som du plejer, men du kan mærke, at du kommer i større og større ubalance, og måske mærker du allerede stresssymptomer og konsekvenser i dit privat- og arbejdsliv."
        const bottomText4 = "På dette coachingforløb guider jeg dig til at:"
        const bottomText5 = "Blive bedre til at håndtere livets pres og forebygge stress"
        const bottomText6 = "Sætte dig selv fri af dine uhensigtsmæssige stressmønstre"
        const bottomText7 = "Stå stærkt i dig selv gennem højt selvværd og personlig ledelse"
        const bottomText8 = "Få mere balance, overskud og livsglæde"
        const bottomText9 = "Du er altid velkommen til at kontakte mig via kontaktformularen for at høre mere."

        const kontaktMig = "Kontakt mig"
        if (!this.state.isMobile) {
            return (
                <div>
                    <MajMenu />
                    <div class="aboutheading">{coaching}</div>

                    <div>
                        <div id="1" class="top">


                            <div id="6" class="left">

                                <div class="margin10Top">
                                    <div id="v1" class="boxText">
                                        <div class="white">
                                            <div id="6" class="boxHeadline">
                                                {bookHeadline}
                                            </div>

                                            <br></br>
                                            {bookText1}
                                            <br></br>
                                            <br></br>
                                            {bookText2}

                                            <br></br>
                                            <br></br>
                                            {bookText3}

                                        </div>
                                        <div class="infoRightBodyButtonWrapperjt">
                                            <Link to="/kontakt">   <button type="button" class="infoRightBodyButton">{bookButton}</button></Link>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div id="1" class="middleBox">

                        <div id="6" class="middleTextHeader">
                            {middleHeadline}
                        </div>
                        <br></br>

                        {middleText1}<br></br><br></br>

                        {middleText2}<br></br><br></br>

                        {middleText3}<br></br><br></br>

                        {middleText4}<br></br><br></br><br></br>
                        <div >
                            <Link to="/andresiger">   <button type="button" class="aboutwelcomeRightBodyButtonJs" >{laesHvadAndreSiger}</button></Link>

                        </div>
                    </div>


                    <div id="1" class="middle">

                        <div class="left" >
                            <div id="3" class="box">
                                <div class="margin10Top">

                                    <div id="v1" class="boxText">
                                        <div id="6" class="boxHeadline">
                                            {bottomHeadline}
                                        </div>
                                        <br></br>

                                        {bottomText1}<br></br><br></br>

                                        {bottomText2}<br></br><br></br>

                                        {bottomText3}<br></br><br></br>

                                        {bottomText4}<br></br>

                                        <ul>
                                            <li>{bottomText5}</li>
                                            <br></br>
                                            <li>{bottomText6}</li>
                                            <br></br>
                                            <li>{bottomText7}</li>
                                            <br></br>
                                            <li>{bottomText8}</li>
                                        </ul>

                                        {bottomText9} <br></br><br></br>

                                        <div >
                                            <Link to="/kontakt">   <button type="button" class="aboutwelcomeRightBodyButtonJs" >{kontaktMig}</button></Link>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="right" >
                            <div class="margin10Top">
                                <div id="v1" class="boxText">
                                    <div id="1" class="middlepictureright">
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <Bottom />

                </div >


            );
        } else {
            return (
                <div>
                    <MajMenuMobile />

                    <div class="headingMobile">{coaching}</div>

                    <div class="squareSectionsJegTilbyder">

                        <div id="6" class="squareSectionsText">
                            <div class="smallBoxTextMobileSpecial">{bookHeadline}</div>


                            <br></br>
                            {bookText1}
                            <br></br>
                            <br></br>
                            {bookText2}

                            <br></br>
                            <br></br>
                            {bookText3}
                        </div>
                        <div class="bookbuttondiv">
                            <Link to="/kontakt">   <button type="button" class="bookButton">{bookButton}</button></Link>
                        </div>

                    </div>

                    <div class="omsections">

                        <div class="omsectionsHeading">{middleHeadline}</div>

                        {middleText1}<br></br><br></br>

                        {middleText2}<br></br><br></br>

                        {middleText3}<br></br><br></br>

                        {middleText4}<br></br><br></br>

                    </div>

                    <div >
                        <Link to="/andresiger">   <button type="button" class="andreSigerButton" >{laesHvadAndreSiger}</button></Link>

                    </div>

                    <div class="omsections">

                        <div class="omsectionsHeading">{bottomHeadline}</div>

                        {bottomText1}<br></br><br></br>

                        {bottomText2}<br></br><br></br>

                        {bottomText3}<br></br><br></br>

                        {bottomText4}<br></br>

                        <ul>
                            <li>{bottomText5}</li>
                            <br></br>
                            <li>{bottomText6}</li>
                            <br></br>
                            <li>{bottomText7}</li>
                            <br></br>
                            <li>{bottomText8}</li>
                        </ul>

                        {bottomText9}<br></br><br></br>

                    </div>

                    <div >
                        <Link to="/kontakt">   <button type="button" class="andreSigerButton" >{kontaktMig}</button></Link>

                    </div>

                    <div id="1" class="jegtilbyder-middle-picture">
                    </div>

                    <BottomMobile />

                </div>


            );
        }
    }
}

export default JegTibyder;
