
import './AndreSiger.css';
import './AndreSigerMobile.css';
import './MobileCommon.css';
import React, { Component } from 'react';

import MajMenu from './MajMenu';
import Bottom from './Bottom';
import MajMenuMobile from './MajMenuMobile';
import BottomMobile from './BottomMobile';

class AndreSiger extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isMobile: window.innerWidth <= 500
        };
    }

    updateIsMobile() {
        this.setState({
            isMobile: window.innerWidth <= 500
        });
    }


    componentDidMount() {
        window.scrollTo(0, 0);
        window.addEventListener('resize', this.updateIsMobile);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateIsMobile);
    }

    render = () => {

        if (!this.state.isMobile) {
            return (
                <div>
                    <MajMenu />
                    <div class="aboutheading">ANDRE SIGER</div>

                    <div id="1" class="andresiger">
                        <div id="2" class="andresigerwelcomeLeft">

                            <div class="box9">

                                <div id="9" class="box9text">
                                    <div class="box9Headline">
                                        Jeg er blevet gladere og har fået meget mere overskud
                                    </div>

                                    <div class="white">
                                        <br></br>
                                        Jeg har lært mig selv at kende, hvilket kan virke mærkeligt, når man er 41 år gammel.
                                        Inden vores coachingforløb levede jeg efter antagelser om, hvad der var vigtigt for mig, uden at lytte til mig selv og mærke efter, hvad der virkelig betød noget.<br></br><br></br>

                                        Din vejledning har givet mig klarhed over mine værdier og hvad der virkelig bringer mig glæde, så jeg kan fokusere på det, der gør mig glad. Desuden har jeg fundet ro og prioriteringsfærdigheder. Jeg har fået et kompas at følge, så det bliver lettere for mig at vurdere, hvad der er rigtigt og forkert, når jeg står over for en presset situation. Jeg er også blevet meget afklaret om, hvad der er vigtigt i mit arbejdsliv, så jeg kan navigere i det.<br></br><br></br>

                                        Jeg har nu fokus på min familie og mine børn, og på at leve i nuet og nyde de fantastiske øjeblikke, det giver. Jeg er blevet gladere og har fået meget mere overskud. Sidst men ikke mindst har jeg fået modet til at være mig selv og ikke bekymre mig om, hvad andre tænker.<br></br><br></br>

                                        De må tage mig som den, jeg er, i stedet for den jeg forsøger at fremstå som, hvis de ønsker mit selskab. Jeg er god, som jeg er, og jeg har meget at tilbyde andre. I denne sammenhæng har jeg lært, at jeg skal blive bedre til at række ud, selvom det ikke føles naturligt, da jeg altid har været meget genert. Jo mere man giver, jo mere får man tilbage.<br></br><br></br>

                                        -Klient på forløbet Personlig ledelse<br></br>
                                    </div>
                                </div>
                            </div>

                            <div class="box8">

                                <div id="6" class="box8text">
                                    <div class="box8Headline">
                                        Du kom som kaldet
                                    </div>

                                    <div class="black">
                                        <br></br>
                                        Tusinde tak for din hjælp. Du kom som kaldet. Efter et langt psykologforløb, var jeg klar til noget andet. Det har været en gave, at lære mig selv og mine adfærdsmønstre bedre at kende, men nu var jeg klar til handling. <br></br><br></br>

                                        Dit forløb har givet min redskaber til at handle og agere. Din måde at bruge visualiseringsøvelser og kroppen på skabte en større helhed og bedre kontakt mellem krop og følelser, noget som jeg også har savnet ved psykologbehandlingerne.<br></br><br></br>

                                        Jeg har i den grad brug for at skrue ned for hjernen og dens ikke altid hensigtsmæssige vaner og mønstre og i stedet mærke kroppen og lytte til dens signaler og tillade gamle sjælesår og undertrykte følelser at blive omfavnet og helet. Det har vores forløb sammen sat skub i og tak for det! <br></br><br></br>

                                        Jeg er endnu ikke, hvor jeg gerne vil være, men med dine redskaber og kloge ord er jeg godt på vej. Derudover er du et fantastisk dejligt og varmt menneske at snakke med, og jeg følte mig enormt tryg og havde nemt ved at åbne op. Du er nærværende, lyttende og imødekommende. Jeg har ikke andet end ros og de bedste anbefalinger til dig.<br></br><br></br>

                                        -Malene, klient på forløbet Personlig ledelse<br></br>
                                    </div>
                                </div>
                            </div>

                            <div class="box7">

                                <div id="6" class="box7text">
                                    <div class="box7Headline">
                                        Maj har gjort mig til et helt menneske - det var jeg ikke da jeg kom
                                    </div>

                                    <div class="white">
                                        <br></br>
                                        Det har været den vildeste rejse. Jeg har flyttet mig 1000 gange tættere på det gode liv. <br></br><br></br>

                                        Jeg er blevet mere bevidst om mig selv, og hvad der er vigtigt for mig. Jeg har lært mig selv at kende og blevet mere bevidst om nogle sider af mig selv.   <br></br><br></br>

                                        I starten tænkte jeg godt nok, at det med at skulle sætte en form på en følelse, det var lidt langhåret, men det har virkelig flyttet mig.   <br></br><br></br>

                                        Jeg er dybt imponeret over måden Maj går ind i folk på. Det er den bedste gave, jeg nogen sinde har givet til mig selv. Jeg kan virkelig selv se, at jeg har flyttet mig.   <br></br><br></br>

                                        Maj har gjort mig til et helt menneske - det var jeg ikke da jeg kom.  <br></br><br></br>

                                        -Mikkel, klient på forløbet Personlig ledelse<br></br>



                                    </div>
                                </div>
                            </div>
                            <div class="box1">

                                <div id="6" class="box1text">
                                    <div class="box1Headline">
                                        Maj er en virkelig passioneret kvinde som forstår at sætte sig helt ind i mit sted
                                    </div>

                                    <div class="black">
                                        <br></br>
                                        Efter en lang periode hvor jeg har ”misligholdt” mig selv, glemt at mærke efter og hamsterhjulet bare har kørt i højeste gear endte jeg med at gå ned med stres.
                                        <br></br><br></br>
                                        Jeg prøvede både egen læge og psykolog men ingen steder kunne jeg få praktiske værktøjer og strategier til min hverdag. Netop værktøjer jeg kunne trække op af hatten når jeg igen mærkede ubehag i min krop og alle stress tegnende kom snigende – Her kommer Maj ind i billedet.
                                        <br></br><br></br>
                                        Igennem mine sessioner med Maj er jeg kommet langt tættere ind på, hvad det er for vaner og mønstre der rør sig ind i mig, hvorfor jeg reagere som jeg gør i forskellige kontekster. Vi har genbesøgt min barndom, ungdom, studietid og da jeg blev mor for at spotte hvorfor jeg altid har følelsen af ikke at være god nok.
                                        <br></br><br></br>
                                        Maj har hjulpet mig med at genfortælle historien i de tanker, som kører inde i mit hoved og gjort mig bevidst om hvad det er for tanker og mønstre jeg skal reagere på, som har en positiv hensigt og hvilke nogle tanker jeg bare skal lade passere som et tog der kører forbi togstationen.
                                        <br></br><br></br>
                                        Maj har åbnet en helt ny verden for mig, hvor jeg får illustreret mit sande jeg i billeder, farver, lyde. Jeg mærker mine behov og kan sige fra overfor andre men mindst lige så vigtigt overfor mig selv. Maj er en virkelig passioneret kvinde som forstår at sætte sig helt ind i mit sted.
                                        <br></br><br></br>
                                        Til trods for Maj ikke har haft et langt virke som Coach har hun en kæmpe værktøjskasse og hvis ikke det ene værktøj virker, finder hun et nyt frem for hun giver ikke op, hun brænder virkelig for at se mig lykkes med min udvikling.
                                        <br></br><br></br>
                                        Maj lover ikke noget quick-fix for personligt lederskab tager tid men hold da op hvor vil jeg heller ikke være rejsen foruden.
                                        <br></br><br></br>
                                        -Ann-Katrine, klient på forløbet Personlig ledelse

                                    </div>
                                </div>
                            </div>
                            <div class="box2">

                                <div id="6" class="box2text">
                                    <div class="box2Headline">
                                        Anbefaling af Maj som coach
                                    </div>
                                    <div class="white">
                                        <br></br>
                                        “Efter nogle sessioner hos Maj, har jeg oplevet en masse afklaringer på nogle relationer, der har været svære for mig at bygge videre på. Maj har dog været rigtig god til at vejlede mig gennem forskellige øvelser, hvor jeg har måttet se tingene i nye perspektiver og det har virkelig givet mig en masse aha-oplevelser undervejs.
                                        <br></br><br></br>
                                        Maj er ligeledes meget hjertevarm og jeg fandt hurtig tryghed i hendes nærvær, så det at snakke om ømtålelige situationer var slet ikke så svært. Hun er rigtig god til at lytte og lade mig komme frem til nye svar og afklaringer i mit liv gennem personlig udvikling.
                                        <br></br><br></br>
                                        Maj er meget forstående og anerkendende, hun formår at coache hvor der både er plads til humor, omsorg, varme og en følelse af ‘du kan godt - for jeg skal nok hjælpe dig’.”
                                        <br></br><br></br>
                                        -Anonym
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div id="3" class="andresigeraboutwelcomeRight">
                            <div id="welcomeRightBody" class="andresigeraboutwelcomeRightBody">
                                <div class="box10">

                                    <div id="6" class="box10text">
                                        <div class="box10Headline">
                                            I experienced the relief from the beginning
                                        </div>
                                        <div class="black">
                                            <br></br>
                                            "I feel lucky to have Maj as my Stress Counsellor. I found her trust worthy, who understands and relates to my emotional challenges and took my case with genuine empathy and compassion.
                                            <br></br><br></br>
                                            Maj's calm personality, expertise knowledge plus her personal experiences about stress, I experienced the relief from beginning.
                                            <br></br><br></br>
                                            Maj has a unique style to demonstrate the use of stress tools was very effective for me.
                                            <br></br><br></br>
                                            Thank you Maj, for being committed and sensitive dealing with my case."
                                            <br></br><br></br>
                                            -Sudipto, klient på forløbet Personlig ledelse
                                        </div>
                                    </div>
                                </div>
                                <div class="box3">

                                    <div id="6" class="box3text">
                                        <div class="box3Headline">
                                            Min varmeste anbefaling af Maj
                                        </div>
                                        <div class="white">
                                            <br></br>
                                            “Det er lidt svært at sætte ord på hvor fantastisk en coach hun er, fordi det falder hende så naturligt. Hun skaber et kærligt og trygt rum, sådan at det er let at spore sig ind på, det som fylder.
                                            <br></br><br></br>
                                            Jeg har et par gange haft brug for retning og et overblik over min hverdag. Jeg er mor til to børn. Der er et ønske om at fylde mig selv op - egenomsorg. Maj er selv mor og tidligere business kvinde med stort B og K. Jeg nyder hendes reflekterende tanker i hendes IG stories.
                                            <br></br><br></br>
                                            Jeg har i sessioner med Maj fået øjnene op for nogle af mine blind spots. Hun har lavet fantastiske øvelser og hjulpet mig frem i lethed - hvad jeg gik og havde modstand på strukturmæssigt og vendt det til en styrke og en frihed. Jeg elsker hendes blide væsen og energi - men at hun samtidig stiller de svære og direkte spørgsmål, sådan at der spores frem til den rigtige løsning.
                                            <br></br><br></br>
                                            Jeg vil anbefale alle at booke en session hos Maj. Hun rummer et skarpt overblik og holder rummet for, at man selv kærligt finder frem til en løsning. Nogle ting vil kræve mere end en session. Men min sidste problemstilling blev løst ved en enkelt session, og den har fuldstændig ændret vores hverdag herhjemme.
                                            <br></br><br></br>
                                            Vi har startet alle vores morgener i ro. Jeg har den vision vi øvede helt klart i billeder for mig, sådan, at jeg let tapper ind i den følelse og derfor skaber den morgen.
                                            En morgen, hvor ro og nærvær er i fokus… det kan vi/jeg på baggrund af den session, det har været magisk, intet mindre”.
                                            <br></br><br></br>
                                            -Anonym
                                        </div>
                                    </div>
                                </div>
                                <div class="box4">

                                    <div id="6" class="box4text">
                                        <div class="box4Headline">
                                            Jeg syntes mit forløb var helt vildt godt
                                        </div>
                                        <div class="black">
                                            <br></br>
                                            “Jeg lærte en masse ting om mig selv og at forstå mig selv bedre. Jeg fik mere indsigt i mig selv som person, og hvad jeg kunne gøre anderledes.
                                            <br></br><br></br>
                                            Med de nlp teknikker som du præsenterede for mig, var det nemmere at huske mig selv på, hvad jeg skal arbejde med og har allerede gjort store fremskridt, lært at sige fra og ikke overskride mine grænser og arbejder fortsat videre”.
                                            <br></br><br></br>
                                            -Anonym
                                        </div>
                                    </div>
                                </div>

                                <div class="box5">

                                    <div id="6" class="box5text">
                                        <div class="box5Headline">
                                            Maj var super dygtig til at skabe et trygt rum...
                                        </div>
                                        <div class="white">
                                            <br></br>
                                            “Det har været sådan en fornøjelse at have to coaching-sessioner med Maj.
                                            <br></br><br></br>
                                            Jeg kom ind med et helt konkret mål, og jeg kom ud med en større forståelse af mig selv og en ønsket adfærdsændring. Maj var super dygtig til at skabe et trygt rum og finde balancen mellem at stille de rigtige spørgsmål og lytte i stilhed.
                                            <br></br><br></br>
                                            Jeg troede faktisk ikke, jeg ville få så meget ud af to sessioner, men jeg er overrasket over, hvor mange tanker, værdier og overbevisninger der kom frem for at blive observeret og analyseret.
                                            <br></br><br></br>
                                            Det er tydeligt, at Maj bygger sin coaching på noget livserfaring, som jeg ville ønske en yngre version af mig havde opsøgt tidligere”.
                                            <br></br><br></br>
                                            -Anonym

                                        </div>
                                    </div>
                                </div>
                                <div class="box6">

                                    <div id="6" class="box6text">
                                        <div class="box6Headline">
                                            Jeg vil anbefale alle at komme hos Maj
                                        </div>
                                        <div class="black">
                                            <br></br>
                                            Jeg har netop afsluttet et coachingforløb hos Maj, og jeg har lært en masse, som jeg vil tage med videre.
                                            <br></br><br></br>
                                            Når man træder ind i lokalet hos Maj, bliver man mødt med et smil, en varme og en forståelse, som jeg ikke synes man finder mange andre steder.
                                            <br></br><br></br>
                                            Jeg startede hos Maj med et håb om at finde hoved og hale i mine mange bekymringer om fremtiden og mine tanker om mig selv og mit værd.
                                            <br></br><br></br>
                                            Maj har hjulpet mig med at fokusere på nutiden og lært at være taknemmelig for livet - ligenu. Jeg har fået håndgribelige værktøjer med, som jeg kan bruge, når de negative tanker igen banker på.
                                            <br></br><br></br>
                                            Jeg vil anbefale alle at komme hos Maj. Jeg har i hvertfald ikke fortrudt ét eneste sekund.
                                            <br></br><br></br>
                                            -Amanda

                                        </div>
                                    </div>
                                </div>




                            </div>
                            <br></br>
                            <br></br>
                        </div>
                    </div>
                    <Bottom />

                </div>


            );
        } else {
            return (
                <div>
                    <MajMenuMobile />
                    <div class="headingMobile">ANDRE SIGER</div>

                    <div id="6" class="squareSections">
                        <div class="squareSectionsText">
                            <div class="squareSectionsHeadingWhite">
                                Jeg er blevet gladere og har fået meget mere overskud
                            </div>
                            Jeg har lært mig selv at kende, hvilket kan virke mærkeligt, når man er 41 år gammel.
                            Inden vores coachingforløb levede jeg efter antagelser om, hvad der var vigtigt for mig, uden at lytte til mig selv og mærke efter, hvad der virkelig betød noget.<br></br><br></br>

                            Din vejledning har givet mig klarhed over mine værdier og hvad der virkelig bringer mig glæde, så jeg kan fokusere på det, der gør mig glad. Desuden har jeg fundet ro og prioriteringsfærdigheder. Jeg har fået et kompas at følge, så det bliver lettere for mig at vurdere, hvad der er rigtigt og forkert, når jeg står over for en presset situation. Jeg er også blevet meget afklaret om, hvad der er vigtigt i mit arbejdsliv, så jeg kan navigere i det.<br></br><br></br>

                            Jeg har nu fokus på min familie og mine børn, og på at leve i nuet og nyde de fantastiske øjeblikke, det giver. Jeg er blevet gladere og har fået meget mere overskud. Sidst men ikke mindst har jeg fået modet til at være mig selv og ikke bekymre mig om, hvad andre tænker.<br></br><br></br>

                            De må tage mig som den, jeg er, i stedet for den jeg forsøger at fremstå som, hvis de ønsker mit selskab. Jeg er god, som jeg er, og jeg har meget at tilbyde andre. I denne sammenhæng har jeg lært, at jeg skal blive bedre til at række ud, selvom det ikke føles naturligt, da jeg altid har været meget genert. Jo mere man giver, jo mere får man tilbage.<br></br><br></br>

                            -Klient på forløbet Personlig ledelse<br></br>


                        </div>
                    </div>

                    <div id="6" class="squareSectionsGrey">
                        <div class="squareSectionsTextBlack">
                            <div class="squareSectionsHeadingBlack">
                                I experienced the relief from the beginning
                            </div>
                            <br></br>
                            "I feel lucky to have Maj as my Stress Counsellor. I found her trust worthy, who understands and relates to my emotional challenges and took my case with genuine empathy and compassion.
                            <br></br><br></br>
                            Maj's calm personality, expertise knowledge plus her personal experiences about stress, I experienced the relief from beginning.
                            <br></br><br></br>
                            Maj has a unique style to demonstrate the use of stress tools was very effective for me.
                            <br></br><br></br>
                            Thank you Maj, for being committed and sensitive dealing with my case."
                            <br></br><br></br>
                            -Sudipto, klient på forløbet Personlig ledelse<br></br>

                        </div>
                    </div>

                    <div id="6" class="squareSections">
                        <div class="squareSectionsText">
                            <div class="squareSectionsHeadingWhite">
                                Du kom som kaldet
                            </div>
                            Tusinde tak for din hjælp. Du kom som kaldet. Efter et langt psykologforløb, var jeg klar til noget andet. Det har været en gave, at lære mig selv og mine adfærdsmønstre bedre at kende, men nu var jeg klar til handling.
                            <br></br><br></br>
                            Dit forløb har givet min redskaber til at handle og agere. Din måde at bruge visualiseringsøvelser og kroppen på skabte en større helhed og bedre kontakt mellem krop og følelser, noget som jeg også har savnet ved psykologbehandlingerne.
                            <br></br><br></br>
                            Jeg har i den grad brug for at skrue ned for hjernen og dens ikke altid hensigtsmæssige vaner og mønstre og i stedet mærke kroppen og lytte til dens signaler og tillade gamle sjælesår og undertrykte følelser at blive omfavnet og helet. Det har vores forløb sammen sat skub i og tak for det!
                            <br></br><br></br>
                            Jeg er endnu ikke, hvor jeg gerne vil være, men med dine redskaber og kloge ord er jeg godt på vej. Derudover er du et fantastisk dejligt og varmt menneske at snakke med, og jeg følte mig enormt tryg og havde nemt ved at åbne op. Du er nærværende, lyttende og imødekommende. Jeg har ikke andet end ros og de bedste anbefalinger til dig.
                            <br></br><br></br>
                            -Malene, klient på forløbet Personlig ledelse


                        </div>
                    </div>

                    <div id="6" class="squareSectionsGrey">
                        <div class="squareSectionsTextBlack">
                            <div class="squareSectionsHeadingBlack">
                                Maj har gjort mig til et helt menneske - det var jeg ikke da jeg kom
                            </div>
                            Det har været den vildeste rejse. Jeg har flyttet mig 1000 gange tættere på det gode liv. <br></br><br></br>

                            Jeg er blevet mere bevidst om mig selv, og hvad der er vigtigt for mig. Jeg har lært mig selv at kende og blevet mere bevidst om nogle sider af mig selv.   <br></br><br></br>

                            I starten tænkte jeg godt nok, at det med at skulle sætte en form på en følelse, det var lidt langhåret, men det har virkelig flyttet mig.   <br></br><br></br>

                            Jeg er dybt imponeret over måden Maj går ind i folk på. Det er den bedste gave, jeg nogen sinde har givet til mig selv. Jeg kan virkelig selv se, at jeg har flyttet mig.   <br></br><br></br>

                            Maj har gjort mig til et helt menneske - det var jeg ikke da jeg kom.  <br></br><br></br>

                            -Mikkel, klient på forløbet Personlig ledelse<br></br>


                        </div>
                    </div>

                    <div id="6" class="squareSections">
                        <div class="squareSectionsText">
                            <div class="squareSectionsHeadingWhite">
                                Maj er en virkelig passioneret kvinde som forstår at sætte sig helt ind i mit sted
                            </div>
                            Efter en lang periode hvor jeg har ”misligholdt” mig selv, glemt at mærke efter og hamsterhjulet bare har kørt i højeste gear endte jeg med at gå ned med stres.
                            <br></br><br></br>
                            Jeg prøvede både egen læge og psykolog men ingen steder kunne jeg få praktiske værktøjer og strategier til min hverdag. Netop værktøjer jeg kunne trække op af hatten når jeg igen mærkede ubehag i min krop og alle stress tegnende kom snigende – Her kommer Maj ind i billedet.
                            <br></br><br></br>
                            Igennem mine sessioner med Maj er jeg kommet langt tættere ind på, hvad det er for vaner og mønstre der rør sig ind i mig, hvorfor jeg reagere som jeg gør i forskellige kontekster. Vi har genbesøgt min barndom, ungdom, studietid og da jeg blev mor for at spotte hvorfor jeg altid har følelsen af ikke at være god nok.
                            <br></br><br></br>
                            Maj har hjulpet mig med at genfortælle historien i de tanker, som kører inde i mit hoved og gjort mig bevidst om hvad det er for tanker og mønstre jeg skal reagere på, som har en positiv hensigt og hvilke nogle tanker jeg bare skal lade passere som et tog der kører forbi togstationen.
                            <br></br><br></br>
                            Maj har åbnet en helt ny verden for mig, hvor jeg får illustreret mit sande jeg i billeder, farver, lyde. Jeg mærker mine behov og kan sige fra overfor andre men mindst lige så vigtigt overfor mig selv. Maj er en virkelig passioneret kvinde som forstår at sætte sig helt ind i mit sted.
                            <br></br><br></br>
                            Til trods for Maj ikke har haft et langt virke som Coach har hun en kæmpe værktøjskasse og hvis ikke det ene værktøj virker, finder hun et nyt frem for hun giver ikke op, hun brænder virkelig for at se mig lykkes med min udvikling.
                            <br></br><br></br>
                            Maj lover ikke noget quick-fix for personligt lederskab tager tid men hold da op hvor vil jeg heller ikke være rejsen foruden.
                            <br></br><br></br>
                            -Ann-Katrine, klient på forløbet Personlig ledelse


                        </div>
                    </div>

                    <div class="squareSectionsGrey">

                        <div id="6" class="squareSectionsTextBlack">
                            <div class="squareSectionsHeadingBlack">
                                Anbefaling af Maj som coach
                            </div>
                            “Efter nogle sessioner hos Maj, har jeg oplevet en masse afklaringer på nogle relationer, der har været svære for mig at bygge videre på. Maj har dog været rigtig god til at vejlede mig gennem forskellige øvelser, hvor jeg har måttet se tingene i nye perspektiver og det har virkelig givet mig en masse aha-oplevelser undervejs.
                            <br></br><br></br>
                            Maj er ligeledes meget hjertevarm og jeg fandt hurtig tryghed i hendes nærvær, så det at snakke om ømtålelige situationer var slet ikke så svært. Hun er rigtig god til at lytte og lade mig komme frem til nye svar og afklaringer i mit liv gennem personlig udvikling.
                            <br></br><br></br>
                            Maj er meget forstående og anerkendende, hun formår at coache hvor der både er plads til humor, omsorg, varme og en følelse af ‘du kan godt - for jeg skal nok hjælpe dig’.”
                            <br></br><br></br>
                            -Anonym

                        </div>
                    </div>

                    <div id="6" class="squareSections">
                        <div class="squareSectionsText">
                            <div class="squareSectionsHeadingWhite">
                                Min varmeste anbefaling af Maj
                            </div>
                            “Det er lidt svært at sætte ord på hvor fantastisk en coach hun er, fordi det falder hende så naturligt. Hun skaber et kærligt og trygt rum, sådan at det er let at spore sig ind på, det som fylder.
                            <br></br><br></br>
                            Jeg har et par gange haft brug for retning og et overblik over min hverdag. Jeg er mor til to børn. Der er et ønske om at fylde mig selv op - egenomsorg. Maj er selv mor og tidligere business kvinde med stort B og K. Jeg nyder hendes reflekterende tanker i hendes IG stories.
                            <br></br><br></br>
                            Jeg har i sessioner med Maj fået øjnene op for nogle af mine blind spots. Hun har lavet fantastiske øvelser og hjulpet mig frem i lethed - hvad jeg gik og havde modstand på strukturmæssigt og vendt det til en styrke og en frihed. Jeg elsker hendes blide væsen og energi - men at hun samtidig stiller de svære og direkte spørgsmål, sådan at der spores frem til den rigtige løsning.
                            <br></br><br></br>
                            Jeg vil anbefale alle at booke en session hos Maj. Hun rummer et skarpt overblik og holder rummet for, at man selv kærligt finder frem til en løsning. Nogle ting vil kræve mere end en session. Men min sidste problemstilling blev løst ved en enkelt session, og den har fuldstændig ændret vores hverdag herhjemme.
                            <br></br><br></br>
                            Vi har startet alle vores morgener i ro. Jeg har den vision vi øvede helt klart i billeder for mig, sådan, at jeg let tapper ind i den følelse og derfor skaber den morgen.
                            En morgen, hvor ro og nærvær er i fokus… det kan vi/jeg på baggrund af den session, det har været magisk, intet mindre”.
                            <br></br><br></br>
                            -Anonym

                        </div>
                    </div>

                    <div class="squareSectionsGrey">

                        <div id="6" class="squareSectionsTextBlack">
                            <div class="squareSectionsHeadingBlack">
                                Jeg syntes mit forløb var helt vildt godt
                            </div>
                            “Jeg lærte en masse ting om mig selv og at forstå mig selv bedre. Jeg fik mere indsigt i mig selv som person, og hvad jeg kunne gøre anderledes.
                            <br></br><br></br>
                            Med de nlp teknikker som du præsenterede for mig, var det nemmere at huske mig selv på, hvad jeg skal arbejde med og har allerede gjort store fremskridt, lært at sige fra og ikke overskride mine grænser og arbejder fortsat videre”.
                            <br></br><br></br>
                            -Anonym

                        </div>
                    </div>

                    <div id="6" class="squareSections">
                        <div class="squareSectionsText">
                            <div class="squareSectionsHeadingWhite">
                                Maj var super dygtig til at skabe et trygt rum...
                            </div>
                            “Det har været sådan en fornøjelse at have to coaching-sessioner med Maj.
                            <br></br><br></br>
                            Jeg kom ind med et helt konkret mål, og jeg kom ud med en større forståelse af mig selv og en ønsket adfærdsændring. Maj var super dygtig til at skabe et trygt rum og finde balancen mellem at stille de rigtige spørgsmål og lytte i stilhed.
                            <br></br><br></br>
                            Jeg troede faktisk ikke, jeg ville få så meget ud af to sessioner, men jeg er overrasket over, hvor mange tanker, værdier og overbevisninger der kom frem for at blive observeret og analyseret.
                            <br></br><br></br>
                            Det er tydeligt, at Maj bygger sin coaching på noget livserfaring, som jeg ville ønske en yngre version af mig havde opsøgt tidligere”.
                            <br></br><br></br>
                            -Anonym

                        </div>
                    </div>

                    <div class="squareSectionsGrey">

                        <div id="6" class="squareSectionsTextBlack">
                            <div class="squareSectionsHeadingBlack">
                                Jeg vil anbefale alle at komme hos Maj
                            </div>
                            Jeg har netop afsluttet et coachingforløb hos Maj, og jeg har lært en masse, som jeg vil tage med videre.
                            <br></br><br></br>
                            Når man træder ind i lokalet hos Maj, bliver man mødt med et smil, en varme og en forståelse, som jeg ikke synes man finder mange andre steder.
                            <br></br><br></br>
                            Jeg startede hos Maj med et håb om at finde hoved og hale i mine mange bekymringer om fremtiden og mine tanker om mig selv og mit værd.
                            <br></br><br></br>
                            Maj har hjulpet mig med at fokusere på nutiden og lært at være taknemmelig for livet - ligenu. Jeg har fået håndgribelige værktøjer med, som jeg kan bruge, når de negative tanker igen banker på.
                            <br></br><br></br>
                            Jeg vil anbefale alle at komme hos Maj. Jeg har i hvertfald ikke fortrudt ét eneste sekund.
                            <br></br><br></br>
                            -Amanda

                        </div>
                    </div>

                    <BottomMobile />

                </div >

            );
        }

    }


}

export default AndreSiger;



